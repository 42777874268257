import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  makeStyles,
  Checkbox,
  TextField,
  InputLabel,
  ListItemText,
  Input,
} from "@material-ui/core";

// hooks
import { useStores } from "stores";

type Props = {
  open: boolean;
  isAddBusStop: boolean;
  busStop: BusStop;
  onClose(): void;
};

const MenuProps = {
  PaperProps: {
    style: {
      maxWidth: 500,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    maxWidth: 400,
    minWidth: 300,
  },
}));

export function BusStopInfoModal({
  open,
  busStop,
  isAddBusStop,
  onClose,
}: Props) {
  const { busRoutes } = useStores();
  const classes = useStyles();

  const [routesIds, setRouteIds] = useState([]);
  const [busStopDescription, setBusStopDescription] = useState("");

  useEffect(() => {
    if (!open) return;
    if (busStop.description) {
      setBusStopDescription(busStop.description);
    }
    if (busStop.routes_ids) {
      setRouteIds(busStop.routes_ids);
    }
  }, [busStop, open]);

  const handleClose = () => {
    setRouteIds([]);
    onClose();
  };

  const handleDelete = async () => {
    if (window.confirm("Deseja realmente deletar esta parada?")) {
      await busRoutes.deleteBusStop(busStop.index);
      handleClose();
    }
  };

  const handleUpdate = async () => {
    if (isAddBusStop) {
      await busRoutes.addBusStop(busStop, busStopDescription, routesIds);
      handleClose();
      return;
    }
    await busRoutes.updateBusStop(busStop, busStopDescription, routesIds);
    handleClose();
  };

  const handleChange = (event) => {
    setRouteIds(event.target.value);
  };

  const renderRouteName = (routesId: string[]) =>
    busRoutes.routesData
      .filter((route) => routesId.includes(route.id))
      .map((route) => route.name)
      .join(", ");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {isAddBusStop ? "Adicionar" : "Editar"} Parada
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Descrição"
          value={busStopDescription}
          onChange={({ target }) => setBusStopDescription(target.value)}
          fullWidth
          variant="outlined"
          margin="normal"
        />
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel id="demo-mutiple-checkbox-label">Rotas</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            fullWidth
            value={routesIds}
            onChange={handleChange}
            input={<Input />}
            renderValue={renderRouteName}
            MenuProps={MenuProps}
          >
            {busRoutes.routesData.map((route) => (
              <MenuItem key={route.id} value={route.id}>
                <Checkbox checked={routesIds.indexOf(route.id) > -1} />
                <ListItemText primary={route.name + "- " + route.description} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="inherit"
          disabled={busRoutes.isRequestLoading}
        >
          Fechar
        </Button>
        <Button
          onClick={handleDelete}
          color="secondary"
          disabled={busRoutes.isRequestLoading}
        >
          Deletar Parada
        </Button>
        <Button
          disabled={busRoutes.isRequestLoading}
          onClick={handleUpdate}
          color="primary"
        >
          {busRoutes.isRequestLoading ? (
            <CircularProgress size={18} />
          ) : (
            "Atualizar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
