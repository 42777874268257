import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import { MapComponent } from "components";

import { useEffect, useRef, useState } from "react";

import { useStores } from "stores";
import { BusStopInfoModal } from "components/BusStopInfoModal";

export function RouteMap() {
  //hooks
  const { busRoutes } = useStores();

  const [routeSelected, setRouteSelected] = useState<BusRoute>();
  const [busStopSelected, setBusStopSelected] = useState({} as BusStop);
  const [showBusStopModal, setShowBusStopModal] = useState(false);
  const [isAddBusStop, setIsAddBusStop] = useState(false);
  const [showAllStop, setShowAllStop] = useState(false);
  const [showRouteStop, setShowRouteStop] = useState(false);

  const busStopLatLang = useRef<LatLng>();

  useEffect(() => {
    if (busRoutes.routesData?.length === 0) {
      busRoutes.loadRoutes();
    }

    if (busRoutes.busStopsData?.length === 0) {
      busRoutes.loadBusStop();
    }
  }, [busRoutes.routesData, busRoutes.busStopsData]);

  const routes = busRoutes.routesData.sort(
    (a, b) => a.route_number - b.route_number,
  );

  const truncateText = (text: string, maxLength: number) => {
    if (!text) return "";
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const onSelectBusStop = (busStop: BusStop) => {
    setBusStopSelected(busStop);
    setShowBusStopModal(true);
  };

  const handleClosedModal = () => {
    setShowBusStopModal(false);
    busStopLatLang.current = undefined;
  };

  // const updateRoute = async () => {
  //   const cityId = auth.citySelected.id;
  //   await busRoutesRef(cityId)
  //     .doc(routeSelected.id)
  //     .update({
  //       route: route.map((r) => {
  //         const [longitude, latitude] = r;
  //         return { latitude, longitude };
  //       }),
  //     });
  //   busRoutes.loadRoutes();
  //   alert("Rota atualizada");
  // };

  // const handleAddToutes = async () => {
  //   await busRoutes.convertBusStopToGeoJSON(90, 103);
  //   setTimeout(async () => {
  //     const { size } = await citiesRef
  //       .doc("anapolis")
  //       .collection("routes")
  //       .get();
  //     alert(size);
  //   }, 1000);
  // };

  return (
    <Container style={{ height: "100%", padding: 0 }}>
      <Grid container style={{ height: "100%" }}>
        <Grid
          item
          xs={3}
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Typography variant="h6">Rotas - {routes.length}</Typography>
          {/* <Button variant="contained" fullWidth onClick={handleAddToutes}>
            {busRoutes.isLoading ? <CircularProgress size={24} /> : "Executar"}
          </Button> */}
          <FormControlLabel
            control={
              <Switch
                checked={showRouteStop}
                onChange={() => setShowRouteStop(!showRouteStop)}
                name="checkedB"
                color="primary"
              />
            }
            label="Mostrar pontos rota"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showAllStop}
                onChange={() => setShowAllStop(!showAllStop)}
                name="checkedB"
                color="primary"
              />
            }
            label="Mostrar todos os pontos"
          />
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "scroll",
            }}
          >
            {routes.map((route) => (
              <Button
                key={route.id}
                variant={
                  routeSelected?.id === route.id ? "contained" : "outlined"
                }
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 5,
                }}
                onClick={() => setRouteSelected(route)}
              >
                <Typography variant="h6" style={{ fontSize: 14 }}>
                  {route.route_number} - {route.name} <br />
                  <Typography noWrap variant="body2" style={{ fontSize: 12 }}>
                    {truncateText(route.description, 20)}
                  </Typography>
                </Typography>
              </Button>
            ))}
          </div>
        </Grid>
        <Grid item xs={9}>
          <MapComponent
            route={routeSelected}
            busStops={busRoutes.busStopsData}
            isAddBusStop={isAddBusStop}
            busStopLatLang={busStopLatLang}
            showRouteStop={showRouteStop}
            showAllStop={showAllStop}
            onSelectBusStop={onSelectBusStop}
            setIsAddBusStop={setIsAddBusStop}
          />
        </Grid>
      </Grid>
      <BusStopInfoModal
        open={showBusStopModal}
        isAddBusStop={isAddBusStop}
        busStop={busStopSelected}
        onClose={handleClosedModal}
      />
    </Container>
  );
}
